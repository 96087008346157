<template>
    <div ref="trackerEmbedRef"
         class="tracker-embed-tippy">
        <slot />
    </div>
</template>

<script setup lang="ts">
    import {
        h, onMounted, ref,
    } from 'vue';
    import useTippyPopup from '@/js/composables/useTippyPopup';
    import TrackerEmbedTippyContent from '@/js/components/Tippy/TrackerEmbedTippy/TrackerEmbedTippyContent.vue';
    import { TrackerProps } from '@/js/components/Tippy/TrackerEmbedTippy/types';

    const props = defineProps<TrackerProps>();

    const trackerEmbedRef = ref(null);

    const { disable, enable } = useTippyPopup(trackerEmbedRef, {
        content: h(TrackerEmbedTippyContent, props),
        theme: 'base',
        interactive: true,
        placement: 'bottom',
    });

    onMounted(() => {
        if (props.disabled) {
            disable();
        } else {
            enable();
        }
    });
</script>

<style lang="scss" scoped>
    .tracker-embed-tippy {
        display: inline-block;
        white-space: nowrap;
    }
</style>
